html {
    background-color: var(--);
}

body {
    margin: 0;
    font-family: "Montserrat", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--primary-background) !important;
}

#webpack-dev-server-client-overlay {
    display: none !important
}

.bg-fade {
    transition: background-color 0.5s linear;
}

.chakra-step__indicator {
    transition: all .5s linear;
}

.chakra-step__separator {
    transition: all .5s linear;
}

#loader-progress div {
    transition: width 10s linear 0s;
    border-radius: 10px;
}

.swiper {
    overflow: visible !important;
}

.swiper-wrapper {
    padding: 10px 0px;
}

#chakra-toast-manager-top-right {
    top: 160px !important;
    right: 30px !important;
}

:root {
    --primary-button: #6C5151;
    --primary-hover: #332626;
    --primary-text: #242424;
    --primary-background: #FBF8F4;
    --header-fill: #DFC9B9;
    --review-card: #F2E9E2;
    --secondary-hover: #E5DCDC;
    --disabled-text: #A39F9F;
    --arrow-outline: #CEC8C8;
    --disabled-fill: #E5E1E1;
    --white-chip: #FFFFFF;
    --header-divider: #A48C84;
    --hero-screen: #F0F5F8;
    --progress-fill: #837473;
    --progress-unified: #C7C3C1;
    --review-outline: #CAC0BD;
    --error-color: #CA0F0F;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}

input, textarea {
    font-size: 16px !important;
}

.chat-quote {
    border-left: 3px solid #ccc;
    margin-left: 10px;
    padding-left: 10px;
    font-style: italic !important;
    font-weight: bold;
}

.chat-quote span {
    font-style: italic !important;
}

*:focus, *:focus-visible {
    box-shadow: 0px 0px 1px 1px var(--primary-button) !important;
}

.chakra-pin-input {
    border-color: var(--progress-fill) !important;
}

a {
    text-decoration: underline !important;
}